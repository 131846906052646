import React, { useState, useRef } from 'react';
import { SlMenu } from "react-icons/sl";
import styles from './Header.module.css';
import Logo from "../../images/Logo.svg"
import Logobranco from "../../images/Logobranco.svg"
import { CgClose } from 'react-icons/cg';

// Novo componente Overlay
const Overlay = ({ onClose, isMenuOpen }) => (
  <div className={`${styles.overlay} ${isMenuOpen ? styles.active : ''}`} onClick={onClose}></div>
);

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (targetId) => {
    const element = document.getElementById(targetId);

    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.header}>
      {/* Renderizar o Overlay quando o menu estiver aberto */}
      <Overlay onClose={() => setMenuOpen(false)} isMenuOpen={isMenuOpen} />

      <div className={styles.logo}>
        <img src={Logo} alt="RapidChargeBack" draggable="false" />
      </div>
      <div className={styles.iconheadermob}>
        <SlMenu size={30} style={{ cursor: 'pointer' }} onClick={handleMenuToggle} />
      </div>
      <div className={`${styles.sidemenu} ${isMenuOpen ? styles.open : ''}`}>
        <div style={{ position: 'absolute', right: '20px', top: '20px' }}>
          <CgClose size={24} style={{ cursor: 'pointer' }} onClick={handleMenuToggle} />
        </div>
        <div className={styles.menucontent}>
          <div>
            <img src={Logobranco} alt="RapidChargeBack" draggable="false" />
          </div>
          <div className={styles.menulist}>
          <div>
              <p onClick={() => { scrollToSection('beneficios'); handleMenuToggle(); }} onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)}>Beneficios</p>
              <div className={`${styles.bar} ${isHovered1 ? styles.barAnimation : ''}`} />
            </div>
            <div>
              <p onClick={() => { scrollToSection('especialidades'); handleMenuToggle(); }} onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)}>Especialidades</p>
              <div className={`${styles.bar} ${isHovered2 ? styles.barAnimation : ''}`} />
            </div>
            <div>
              <p onClick={() => { scrollToSection('ajuda'); handleMenuToggle(); }} onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)}>Ajuda</p>
              <div className={`${styles.bar} ${isHovered3 ? styles.barAnimation : ''}`} />
            </div>
            <div>
              <p onClick={() => { scrollToSection('contato'); handleMenuToggle(); }} onMouseEnter={() => setIsHovered4(true)} onMouseLeave={() => setIsHovered4(false)}>Contato</p>
              <div className={`${styles.bar} ${isHovered4 ? styles.barAnimation : ''}`} />
            </div>
          </div>
          <div>
            <button onClick={() => { scrollToSection('planos'); handleMenuToggle(); }}>Preços e Planos</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;