import React, { useState, useRef, useEffect } from 'react';
import styles from './Acordeao.module.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);
  
    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
      }
    }, [isOpen]);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className={`${styles.accordion} ${isOpen ? styles.open : ''}`}>
        <div className={styles.header} onClick={handleToggle}>
          <h2>{title}</h2>
          <div>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
        <div className={styles.content} ref={contentRef} style={{ maxHeight: `${contentHeight}px` }}>
          <p>{content}</p>
        </div>
      </div>
    );
  };
  
  export default Accordion;