import React, { useState, useRef } from 'react';
import styles from './App.module.css';
import Gotas from './images/gotas.svg'
import Header from './components/Header/Header'
import ImageMain1 from './images/Main1.png'
import Logo from './images/Logo.svg'
import Logo2 from './images/Logobranco.svg'
import Icon1 from './images/icons/icon1.svg'
import Icon2 from './images/icons/icon2.svg'
import Icon3 from './images/icons/icon3.svg'
import Icon4 from './images/icons/icon4.svg'
import Icon5 from './images/icons/icon5.svg'
import Icon6 from './images/icons/icon6.svg'
import Icon7 from './images/icons/icon7.svg'
import Icon8 from './images/icons/icon8.svg'
import Icon9 from './images/icons/icon9.svg'
import Item1 from './images/Itens/Item1.svg'
import Item2 from './images/Itens/Item2.svg'
import Item3 from './images/Itens/Item3.svg'
import Item4 from './images/Itens/Item4.svg'
import Item5 from './images/Itens/Item5.svg'
import Item6 from './images/Itens/Item6.svg'
import Item7 from './images/Itens/Item7.svg'
import Item8 from './images/Itens/Item8.svg'
import Item9 from './images/Itens/Item9.svg'
import Item10 from './images/Itens/Item10.svg'
import Item11 from './images/Itens/Item11.svg'
import Item12 from './images/Itens/Item12.svg'
import Item13 from './images/Itens/Item13.svg'
import Item14 from './images/Itens/Item14.svg'
import Main1image from './images/Main1.png'
import Main2image from './images/Main2.png'
import Main6Image from './images/Main6mob.png'
import Blocks from './components/Blocks/Blocks'
import Plans from './components/Plans/Plans'
import Image from './images/null.png'
import Slider from './components/Slider/Slider'
import Acordeao from './components/Acordeao/Acordeao'
import Whats from './images/whatsapp.svg'
import { FaPhone } from "react-icons/fa6";

function App() {
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);

    const slides = [
      {
        text1: 'Texto 1',
        text2: 'Nome do cliente, 31',
        text3: 'São Paulo',
      },
      {
        text1: 'Texto 2',
        text2: 'Nome do cliente, 42',
        text3: 'Rio de Janeiro',
      },
      {
        text1: 'Texto 3',
        text2: 'Nome do cliente, 54',
        text3: 'Salvador',
      },
    ];

    const ajudaRef = useRef(null);
    const contatoRef = useRef(null);
    const especialidadesRef = useRef(null);
    const beneficiosRef = useRef(null);
    const precosPlanosRef = useRef(null);

    const scrollToSection = (ref) => {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.mainheader}>
          <Header/>
          <div className={styles.sub1main1}>
            <img src={Logo} alt="RapidChargeBack" draggable="false" />
            <div className={styles.headerlist}>
              <div>
                <p onClick={() => scrollToSection(beneficiosRef)} onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)}>Beneficios</p>
                <div className={`${styles.bar} ${isHovered2 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(especialidadesRef)} onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)}>Especialidades</p>
                <div className={`${styles.bar} ${isHovered3 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(ajudaRef)} onMouseEnter={() => setIsHovered4(true)} onMouseLeave={() => setIsHovered4(false)}>Ajuda</p>
                <div className={`${styles.bar} ${isHovered4 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(contatoRef)} onMouseEnter={() => setIsHovered5(true)} onMouseLeave={() => setIsHovered5(false)}>Contato</p>
                <div className={`${styles.bar} ${isHovered5 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <button onClick={() => scrollToSection(precosPlanosRef)}>Preços e Planos</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.main1}>
          <div className={styles.main1sub1}>
            <div>
              <div className={styles.fronttitle}>
                <h1>O plano de saúde mais barato do Brasil</h1>
              </div>
              <div className={styles.backtitle}/>
            </div>
            <h2>O Plano do Povo é um plano de saúde particular com cobertura nacional que cabe no seu bolso.</h2>
            <p>Você tem acesso a consultas médicas online e presenciais com clínico geral, especialistas, diagnósticos, exames e muito mais, sem custo adicional*.</p>
            <div>
              <div className={styles.imggotas}>
                <img src={Gotas} alt=''/>
              </div>
              <span>Por apenas</span>
              <div>
                <strong>R$ 24,75</strong>
                <span>/mês</span>
              </div>
            </div>
            <button onClick={() => scrollToSection(precosPlanosRef)}>Saiba mais</button>
          </div>
          <div className={styles.main1sub2}>
            <img src={Main1image}/>
          </div>
        </div>
        <div id='beneficios' ref={beneficiosRef} className={styles.main2}>
          <div className={styles.main2sub1}>
            <img src={Main2image} alt=''/>
          </div>
          <div className={styles.main2sub2}>
            <h3>Benefícios </h3>
            <div className={styles.main2sub2list}>
              <div>
                <img src={Icon5} alt=''/>
                <p>Consultas onlines e presenciais</p>
              </div>
              <div>
                <img src={Icon1} alt=''/>
                <p>Atendimento médico online e presencial 24 horas por dia, 7 dias por semana.</p>
              </div>
              <div>
                <img src={Icon3} alt=''/>
                <p>Odontologia de Urgência</p>
              </div>
              <div>
                <img src={Icon2} alt=''/>
                <p>Cobertura nacional</p>
              </div>
              <div>
                <img src={Icon3} alt=''/>
                <p>Sem Carência - Se consulte ainda hoje!</p>
              </div>
              <div>
                <img src={Icon4} alt=''/>
                <p>Sem Limite de Idade - de 0 a 99 anos</p>
              </div>
              <div>
                <img src={Icon5} alt=''/>
                <p>Sem limite de Consultas</p>
              </div>
              <div>
                <img src={Icon6} alt=''/>
                <p>Desconto em mais de 25 mil farmácias em todo brasil</p>
              </div>
              <div>
                <img src={Icon7} alt=''/>
                <p>Desconto em exames </p>
              </div>
              <div>
                <img src={Icon8} alt=''/>
                <p>Receitas de medicamentos</p>
              </div>
              <div>
                <img src={Icon9} alt=''/>
                <p>Atestados</p>
              </div>
            </div>
            <div className={styles.main2button}>
              <button onClick={() => scrollToSection(precosPlanosRef)}>Preços e Planos</button>
            </div>
          </div>
        </div>
        <div id='especialidades' ref={especialidadesRef} className={styles.main3}>
          <div className={styles.main3sub1}>
            <h3>Especialidades médicas</h3>
            <strong>Tenha Acesso a Especialidades Médicas para Todos da sua família!</strong>
            <p>Cuide da saúde da sua família com a tranquilidade e tenha acesso a uma variedade de especialidades médicas, tudo isso no Plano do Povo:</p>
          </div>
          <div className={styles.main3sub2}>
            <Blocks image={Item1} text="Clínico Geral"/>
            <Blocks image={Item2} text="Pediatra"/>
            <Blocks image={Item3} text="Cardiologista"/>
            <Blocks image={Item4} text="Neurologista"/>
            <Blocks image={Item5} text="Nutricionista"/>
            <Blocks image={Item6} text="Psiquiatra*"/>
            <Blocks image={Item7} text="Traumatologia"/>
            <Blocks image={Item8} text="Ginecologista"/>
            <Blocks image={Item9} text="Urologia"/>
            <Blocks image={Item10} text="Geriatria"/>
            <Blocks image={Item11} text="Otorrino"/>
            <Blocks image={Item12} text="Endocrinologista"/>
            <Blocks image={Item13} text="Psicólogo*"/>
            <Blocks image={Item14} text="Dermatologista"/>
            <Blocks image={null} text="E muito mais!"/>
          </div>
        </div>
        <div id='planos' ref={precosPlanosRef} className={styles.main4}>
          <div className={styles.main4sub1}>
            <h3>Preços e Planos</h3>
          </div>
          <div className={styles.main4sub2}>
            <Plans
              title= 'Plano Individual'
              quantity= '1 VIDA'
              value= '49'
              cents= '90'
              desc01= 'Consultas onlines e presenciais'
              desc0= 'Odontologia de urgência residencial.'
              desc1= 'Consultas ilimitadas'
              desc2= 'Atendimento 24 horas por dia'
              desc3= 'Laudos'
              desc4= 'Pedidos de exames'
              desc5= 'Sem Carência -  Utilização Imediata'
              desc6= 'Diagnósticos'
              desc7= 'Encaminhamentos para médicos especialistas'
              desc8= 'Atestados'
              desc9= 'Receitas de medicamentos'
              desc10= 'Descontos em rede de farmácias credenciadas'
              desc11= 'Sem Limite de Idade - de 0 a 99 anos'
              buttonText= 'Contratar agora'
              buttonLink= 'https://pay.kiwify.com.br/Hzvv407'
            />
            <Plans
              title= 'Plano Família'
              quantity= 'até 4 VIDAs'
              value= '99'
              cents= '90'
              desc01= 'Consultas onlines e presenciais'
              desc0= 'Odontologia de urgência residencial.'
              desc1= 'Consultas ilimitadas'
              desc2= 'Atendimento 24 horas por dia'
              desc3= 'Laudos'
              desc4= 'Pedidos de exames'
              desc5= 'Sem Carência -  Utilização Imediata'
              desc6= 'Diagnósticos'
              desc7= 'Encaminhamentos para médicos especialistas'
              desc8= 'Atestados'
              desc9= 'Receitas de medicamentos'
              desc10= 'Descontos em rede de farmácias credenciadas'
              desc11= 'Sem Limite de Idade - de 0 a 99 anos'
              buttonText= 'Contratar agora'
              buttonLink= 'https://pay.kiwify.com.br/KbPxjQz'
            />
          </div>
        </div>
        <div style={{display: 'none'}} className={styles.main5}>
          <div className={styles.main5sub1}>
            <div className={styles.main5sub1sub1}>
              <h3>Depoimentos</h3>
              <strong>Saiba o que nossos clientes falam sobre nós</strong>
              <p>Ouça a experiência dos nossos clientes e descubra por que somos a escolha certa para sua saúde!</p>
            </div>
            <div>
              <button onClick={() => scrollToSection(precosPlanosRef)}>Contratar agora</button>
            </div>
          </div>
          <div className={styles.main5sub2}>
            {/*<div className={styles.video}>
              <img src={Image}/>
            </div>*/}
            <div className={styles.slider}>
              <Slider slides={slides} />
            </div>
            <div>
              <button onClick={() => scrollToSection(precosPlanosRef)}>Contratar agora</button>
            </div>
          </div>
        </div>
        <div ref={ajudaRef} className={styles.main6}>
          <div id='ajuda' className={styles.main6sub2}>
            <img src={Main6Image}/>
          </div>
          <div className={styles.main6sub1}>
            <h3>Perguntas Frequentes</h3>
            <div>
              <Acordeao
                title="Como funciona?"
                content="Após contratar o serviço, entraremos em contato via telefone ou WhatsApp com todas as instruções sobre como utilizar o Plano do Povo. No entanto, é bem simples: você receberá acesso ao nosso aplicativo e à nossa central de atendimento ao cliente, através dos quais poderá marcar suas consultas presenciais e online imediatamente."
              />
              <Acordeao
                title="O que é o Plano do Povo?"
                content="O Plano do Povo é uma intermediadora de consultas médicas e pronto atendimento online e presencial. Através da nossa plataforma, você entra em contato imediato com um dos nossos médicos, realiza sua consulta, obtém seu diagnóstico e ainda pode receber encaminhamentos ambulatoriais, pedidos de exames, atestados médicos e consultas com outros especialistas, tudo pela tela do seu celular, tablet ou computador."
              />
              <Acordeao
                title="Como faço um atendimento online e presencial?"
                content="Para agendar um atendimento, basta acessar nosso aplicativo ou entrar em contato com nossa central de atendimento pelo WhatsApp e selecionar a especialidade desejada. Se o atendimento for online, o médico realizará uma videochamada para auxiliá-lo. Se for presencial, indicaremos uma clínica próxima da sua residência."
              />
              <Acordeao
                title="Tem alguma fidelidade?"
                content="Nosso plano não possui fidelidade. Você pode cancelar quando desejar."
              />
              <Acordeao
                title="Vocês têm plano empresarial?"
                content="Sim! Atendemos empresas de diversos segmentos e tamanhos com preços especiais."
              />
              <Acordeao
                title="Como é a forma de pagamento?"
                content="O pagamento pode ser feito por cartão de crédito, boleto ou PIX."
              />
              <Acordeao
                title="Contratando o Plano do Povo, quando consigo atendimento?"
                content="Você pode ser atendido por um de nossos profissionais no mesmo dia da contratação."
              />
            </div>
            <button onClick={() => scrollToSection(precosPlanosRef)}>Contratar agora</button>
          </div>
        </div>
        <div id='contato' ref={contatoRef} className={styles.main7}>
          <div className={styles.main7sub1}>
            <div className={`${styles.footerheader} ${styles.footerheader2}`}>
              <h3>Fale conosco</h3>
              <div>
                <a href="https://api.whatsapp.com/send?phone=5521965212298&text=Ol%C3%A1,%20Quero%20adquirir%20o%20plano%20do%20povo.%20mas%20tenho%20algumas%20d%C3%BAvidas">
                  <button><img src={Whats}/> Fale agora</button>
                </a>
                <p>ou (21)96521-2298</p>
              </div>
            </div>
            <div style={{display: 'none'}} className={styles.line1}/>
            <div style={{display: 'none'}} className={`${styles.footerheader} ${styles.footerheader3}`}>
              <h3>Central de atendimento</h3>
              <div>
                <FaPhone size={24} color='white'/>
                <p>0800 000 0000</p>
              </div>
            </div>
          </div>
          <div className={styles.line2}/>
          <div className={styles.main7sub2}>
            <img src={Logo2}/>
            <div className={styles.footerlist}>
              <div>
                <p onClick={() => scrollToSection(beneficiosRef)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>Beneficios</p>
                <div className={`${styles.bar} ${isHovered ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(especialidadesRef)} onMouseEnter={() => setIsHovered6(true)} onMouseLeave={() => setIsHovered6(false)}>Especialidades</p>
                <div className={`${styles.bar} ${isHovered6 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(precosPlanosRef)} onMouseEnter={() => setIsHovered9(true)} onMouseLeave={() => setIsHovered9(false)}>Preços e Planos</p>
                <div className={`${styles.bar} ${isHovered9 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(ajudaRef)} onMouseEnter={() => setIsHovered7(true)} onMouseLeave={() => setIsHovered7(false)}>Ajuda</p>
                <div className={`${styles.bar} ${isHovered7 ? styles.barAnimation : ""}`} />
              </div>
              <div>
                <p onClick={() => scrollToSection(contatoRef)} onMouseEnter={() => setIsHovered8(true)} onMouseLeave={() => setIsHovered8(false)}>Contato</p>
                <div className={`${styles.bar} ${isHovered8 ? styles.barAnimation : ""}`} />
              </div>
            </div>
          </div>
          <div className={styles.main7sub3}>
            <p>© 2024 - Todos os Direitos Reservados</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
