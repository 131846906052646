import React, { useState } from 'react';
import styles from './Slider.module.css';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import SliderImage from '../../images/slider.svg'


const Slider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className={styles.container}>
      <div className={styles.arrowdesktop}>
        <FaArrowLeftLong className={styles.arrow} size={25} onClick={handlePrevSlide}/>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
            <div className={styles.block1}>
                <img src={SliderImage}/>
                <p>{slides[currentSlide].text1}</p>
            </div>
            <div className={styles.block2}>
                <p>{slides[currentSlide].text2}</p>
                <p>{slides[currentSlide].text3}</p>
            </div>
        </div>
        <div className={styles.arrowmodelmobile}>
          <div className={styles.arrowmobile}>
            <FaArrowLeftLong className={styles.arrow} size={25} onClick={handlePrevSlide}/>
          </div>
          <div className={styles.indicators}>
              {slides.map((_, index) => (
              <div
                  key={index}
                  className={`${styles.indicator} ${currentSlide === index ? styles.active : ''}`}
                  onClick={() => setCurrentSlide(index)}
              ></div>
              ))}
          </div>
          <div className={styles.arrowmobile}>
            <FaArrowRightLong className={styles.arrow} size={25} onClick={handleNextSlide}/>
          </div>
        </div>
      </div>
      <div className={styles.arrowdesktop}>
        <FaArrowRightLong className={styles.arrow} size={25} onClick={handleNextSlide}/>
      </div>
    </div>
  );
};

export default Slider;