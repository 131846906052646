import React from 'react';
import styles from './Blocks.module.css';

const Blocks = ({ image, text }) => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={image}/>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Blocks;