import React from 'react';
import styles from './Plans.module.css';
import Check from '../../images/check.svg'

const Plans = ({ title, quantity, value, cents, buttonText, buttonLink, desc01, desc0, desc1, desc2, desc3, desc4, desc5, desc6, desc7, desc8, desc9, desc10, desc11 }) => {
    const handleButtonClick = () => {
        window.location.href = buttonLink;
      };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
            <h3>{title}</h3>
            <p>{quantity}</p>
        </div>
        <div style={{display: 'flex', gap: '3px'}}>
            <span>R$</span>
            <strong>{value}</strong>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>,{cents}</span>
                <span>/mês</span>
            </div>
        </div>
      </div>
      <div className={styles.list}>
        <div>
            <img src={Check}/>
            <p>{desc01}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc0}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc1}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc2}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc3}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc4}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc5}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc6}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc7}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc8}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc9}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc10}</p>
        </div>
        <div>
            <img src={Check}/>
            <p>{desc11}</p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button onClick={handleButtonClick}>{buttonText}</button>
      </div>
    </div>
  );
};

export default Plans;